import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import ContentServices from "../components/Services/ContentServices";
import BaseLayout from "../components/global/BaseLayout";

function Services({rangomin, rangomax}) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Cleaning Services">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="Cleaning Services"
          Subheader="Cleaning Services"
          bgimg={`${rpdata?.gallery?.[0]}`}
        />
        <ContentServices rangomin={rangomin} rangomax={rangomax}/>
      </div>
    </BaseLayout>
  );
}

export default Services;
